import { ROLE } from "../constants";
import axiosInstance from "../utils/axios";

const getUsers = async (role) => {
  try {
    let body = {
      query: `#graphql
        query {
          users {
            _id
            code
            firstname
            lastname
            email
            address
            city
            postcode
            role
            plan
            active
            user {
              _id
            }
            manager {
              _id
            }
          }
        }`,
      variable: { role },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const users = response.data.data.users;
    return users;
  } catch (err) {
    throw err;
  }
};

const getUser = async (id) => {
  try {
    let body = {
      query: `#graphql
        query($id: ID) {
        user(id: $id) {
          _id
          firstname
          lastname
          email
          address
          city
          postcode
          role
          plan
          active
          company {
            _id
            name
            email
            phone
            siret
            orias
            address
            city
            postcode
            country
            capital
            logoUrl
            legalInfoStatus
            legalInfoPartners
            legalInfoFooter
            costsAndFees
            recommendation
            axonautId
            managers {
              _id
            }
          }
          manager {
            _id
            email
            lastname
            firstname
            company {
              _id
              name
              email
              siret
              orias
            }
          }
          users {
            _id
            email
            lastname
            firstname
          }
          admin {
            role
          }
          docs {
            _id
            docType
            isVerified
            isSigned
            signedAt
            name
            filePath
            createdAt
          }
        }
      }`,
      variables: {
        id,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const user = response.data.data.user;
    return user;
  } catch (err) {
    throw err;
  }
};

const addManager = async (companyId, manager) => {
  try {
    const body = {
      query: `#graphql
      mutation ($firstname: String, $lastname: String, $email: String, $password: String, $companyId: ID) {
          createManager (
              firstname: $firstname
              lastname: $lastname
              email: $email
              password: $password
              companyId: $companyId
          )
          {
              _id
          }
      }
      `,
      variables: {
        firstname: manager.firstname,
        lastname: manager.lastname,
        email: manager.email,
        password: manager.password,
        companyId,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const managerId = response.data.data.createManager._id;
    if (managerId) {
      return managerId;
    } else {
      throw new Error("Create manager failed", response.data.data.createManager);
    }
  } catch (err) {
    throw err;
  }
};

const createUser = async (user, managerId, companyId) => {
  try {
    const body = {
      query: `#graphql
      mutation ($firstname: String, $lastname: String, $email: String, $password: String, $managerId: ID, $companyId: ID) {
          createUser (
              firstname: $firstname
              lastname: $lastname
              email: $email
              password: $password
              managerId: $managerId
              companyId: $companyId
          )
          {
              _id
          }// nothing yet, need to show error message
      }
      `,
      variables: {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        managerId,
        companyId,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const userId = response.data.data.createUser._id;
    if (userId) {
      return true;
    } else {
      throw new Error("Create user failed", response.data.data.createUser);
    }
  } catch (err) {
    throw err;
  }
};

const updateUser = async (user, userId) => {
  // We need to send only users id to update the manager
  if (user.users?.length > 0) user.users = user.users.map((u) => u._id);
  // This case is used to add a new user to the manager
  if (userId) user.users.push(userId);

  try {
    const body = {
      query: `#graphql
        mutation (
          $id: ID,
          $firstname: String,
          $lastname: String,
          $address: String,
          $city: String,
          $postcode: String,
          $email: String,
          $users: [ID]
          $active: Boolean
        ) {
        updateUser(
          id: $id
          firstname: $firstname
          lastname: $lastname
          address: $address
          city: $city
          email: $email
          postcode: $postcode
          users: $users
          active: $active
        ) {
          _id
        }
      }
      `,
      variables: {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        address: user.address,
        postcode: user.postcode,
        city: user.city,
        users: user.users,
        active: user.active,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const id = response.data.data.updateUser._id;
    if (id) {
      return true;
    } else {
      throw new Error("Update user failed", response.data.data.updateUser);
    }
  } catch (err) {
    throw err;
  }
};

const updateSingleUser = async (valueObject, userId) => {
  try {
    const body = {
      query: `#graphql
        mutation (
          $id: ID,
          $firstname: String,
          $lastname: String,
          $address: String,
          $city: String,
          $postcode: String,
          $email: String,
          $users: [ID]
          $active: Boolean
          $position: String
        ) {
        updateUser(
          id: $id
          firstname: $firstname
          lastname: $lastname
          address: $address
          city: $city
          email: $email
          postcode: $postcode
          users: $users
          active: $active
          position: $position
        ) {
          _id
        }
      }
      `,
      variables: {
        ...valueObject,
        id: userId,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const id = response.data.data.updateUser?._id;
    if (id) {
      return true;
    } else {
      throw new Error("Update user failed", response.data.data.updateUser);
    }
  } catch (err) {
    throw err;
  }
};

const deleteUser = async (userId) => {
  try {
    const body = {
      query: `#graphql
        mutation ($id: ID) {
          deleteUser(
            id: $id
          ) {
            _id
          }
        }
      `,
      variables: {
        id: userId,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const id = response.data.data.deleteUser._id;
    if (id) {
      return true;
    } else {
      throw new Error("Delete company failed", response.data.data.deleteUser);
    }
  } catch (err) {
    throw err;
  }
};

const updatePlan = async (user, plan) => {
  try {
    const body = {
      query: `#graphql
        mutation (
          $id: ID,
          $plan: Plan
        ) {
        updateUser(
          id: $id
          plan: $plan
        ) {
          _id
        }
      }
      `,
      variables: {
        id: user._id,
        plan: plan,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const id = response.data.data.updateUser._id;
    if (id) {
      return true;
    } else {
      throw new Error("Update plan failed", response.data.data.updateUser);
    }
  } catch (err) {
    throw err;
  }
};

/**
 * This is used to active a GUEST access by change th user role
 * @param {*} user
 * @param {*} giveAccess
 * @returns
 */
const activateAccess = async (user, giveAccess) => {
  try {
    const body = {
      query: `#graphql
        mutation (
          $id: ID,
          $role: Role
        ) {
        updateUser(
          id: $id
          role: $role
        ) {
          _id
        }
      }
      `,
      variables: {
        id: user._id,
        role: giveAccess ? ROLE.ESG_AGENT : ROLE.GUEST,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    const id = response.data.data.updateUser._id;
    if (id) {
      return true;
    } else {
      throw new Error("Update plan failed", response.data.data.updateUser);
    }
  } catch (err) {
    throw err;
  }
};

const getBasicUserInfos = async (id) => {
  try {
    let body = {
      query: `#graphql
      query($id: ID) {
        user(id: $id) {
          _id
          firstname
          lastname
          email
          address
          postcode
          city
        }
      }`,
      variables: {
        id,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    return response.data.data.user;
  } catch (err) {
    throw err;
  }
};

const forgotUserPassword = async (email) => {
  try {
    let body = {
      query: `#graphql
      query($email: String) {
        lostPassword(email: $email) {
          success
          message
        }
      }`,
      variables: {
        email: email,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    if (!response?.data?.data?.lostPassword?.success) {
      throw new Error("Forgot password failed", response.data.data.lostPassword);
    }
    return response.data.data.lostPassword;
  } catch (err) {
    throw err;
  }
};

const resetUserPassword = async (token, email, password) => {
  try {
    let body = {
      query: `#graphql
      mutation($email: String, $token: String, $password: String) {
        resetPassword(email: $email, token: $token, password: $password) {
          success
          message
        }
      }`,
      variables: {
        token: token,
        email: email,
        password: password,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    if (!response?.data?.data?.resetPassword?.success) {
      throw new Error("Reset password failed", response.data.data.resetPassword);
    }
    return response.data.data.resetPassword;
  } catch (err) {
    throw err;
  }
};

const linkToManager = async (userId, managerId) => {
  try {
    let body = {
      query: `#graphql
      query($userId: ID, $managerId: ID) {
        linkToManager(userId: $userId, managerId: $managerId)
      }`,
      variables: {
        userId,
        managerId,
      },
    };

    const response = await axiosInstance({ method: "POST", data: body });
    return response.data.data.linkToManager;
  } catch (err) {
    throw err;
  }
};

export {
  getUsers,
  getUser,
  getBasicUserInfos,
  createUser,
  addManager,
  updateUser,
  deleteUser,
  updateSingleUser,
  updatePlan,
  activateAccess,
  forgotUserPassword,
  resetUserPassword,
  linkToManager,
};
